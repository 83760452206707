import * as React from 'react';
import PropTypes from 'prop-types';

import ERPImage from '../images/erp-image';
import Robust101 from '../images/Robust101';

const WhatIsERP = ({ bgcolor }) => (
  <div className={`py-16 overflow-hidden ${bgcolor} lg:py-24`}>
    <div className="relative max-w-xl px-4 mx-auto sm:px-6 lg:px-8 lg:max-w-screen-xl">
      <svg
        className="absolute hidden transform -translate-x-1/2 lg:block left-full -translate-y-1/4"
        width={404}
        height={784}
        fill="none"
        viewBox="0 0 404 784"
      >
        <defs>
          <pattern
            id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
            x={0}
            y={0}
            width={20}
            height={20}
            patternUnits="userSpaceOnUse"
          >
            <rect
              x={0}
              y={0}
              width={4}
              height={4}
              className="text-gray-200"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect
          width={404}
          height={784}
          fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
        />
      </svg>
      <div className="relative">
        <h3 className="text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl sm:leading-10">
          What is ERP? And what can it do for{' '}
          <span className="text-teal-600">me?</span>
        </h3>
        <p className="max-w-3xl mx-auto mt-4 text-xl leading-7 text-center text-gray-500">
          Enterprise Resource Planning (ERP) software connects all parts of your
          operation, with one database shared among all departments.
        </p>
        <p className="max-w-3xl mx-auto mt-4 text-xl leading-7 text-center text-gray-500">
          it&apos;s not just for large, complex enterprises &mdash; if your
          business has more than one department, you&apos;ll reap huge benefits.
        </p>
        <p className="max-w-3xl mx-auto mt-4 text-xl leading-7 text-center text-gray-500">
          And with Odoo you&apos;ll get all the benefits of ERP at a price that
          fits your business.
        </p>
      </div>
      <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
        <div className="relative">
          <h4 className="text-2xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-3xl sm:leading-9">
            ERP <span className="text-teal-600">integrates</span> your business
            functions
          </h4>
          <p className="mt-3 text-lg leading-7 text-gray-500">
            Your data &mdash; one location, continually updated, easily accessed
            &mdash; is now your <strong>single source of truth</strong> &mdash;
            no more worries about coordinating data updates in multiple
            locations.
          </p>
          <p className="mt-5 text-lg leading-7 text-gray-500">
            {' '}
            All data flows within a single system &mdash;{' '}
            <strong>Odoo ERP</strong> &mdash; not between separate software
            packages or in and out of manually kept records.
          </p>
          <ul className="mt-10">
            <li>
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center w-12 h-12 text-white bg-teal-500 rounded-md">
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5 className="text-lg font-medium leading-6 text-gray-900">
                    Start simple, expand when ready
                  </h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    You can be up and running with just one module, perhaps CRM
                    or Sales. Later, expand your ERP incrementally, module by
                    module, increasing the benefits while minimizing risk.
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center w-12 h-12 text-white bg-teal-500 rounded-md">
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5 className="text-lg font-medium leading-6 text-gray-900">
                    Start seeing the bigger picture
                  </h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    As you add modules to your setup, they are automatically
                    integrated &mdash; and new connections and patterns quickly
                    become visible.
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center w-12 h-12 text-white bg-teal-500 rounded-md">
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M13 10V3L4 14h7v7l9-11h-7z"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5 className="text-lg font-medium leading-6 text-gray-900">
                    Coordinate different parts of your operation
                  </h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    Departmental data is automatically shared, in real time with
                    no special mechanisms. Each department views relevant data
                    across the company in terms meaningful to them.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="relative mt-10 -mx-4 lg:mt-0">
          <svg
            className="absolute transform -translate-x-1/2 translate-y-16 left-1/2 lg:hidden"
            width={784}
            height={404}
            fill="none"
            viewBox="0 0 784 404"
          >
            <defs>
              <pattern
                id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={784}
              height={404}
              fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
            />
          </svg>
          <ERPImage className="relative mx-auto" />
        </div>
      </div>
      <svg
        className="absolute hidden transform translate-x-1/2 translate-y-12 lg:block right-full"
        width={404}
        height={784}
        fill="none"
        viewBox="0 0 404 784"
      >
        <defs>
          <pattern
            id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
            x={0}
            y={0}
            width={20}
            height={20}
            patternUnits="userSpaceOnUse"
          >
            <rect
              x={0}
              y={0}
              width={4}
              height={4}
              className="text-gray-200"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect
          width={404}
          height={784}
          fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
        />
      </svg>
      <div className="relative mt-12 sm:mt-16 lg:mt-24">
        <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="lg:col-start-2">
            <h4 className="text-2xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-3xl sm:leading-9">
              ERP <span className="text-teal-600">automates</span> processing
              and reporting
            </h4>
            <p className="mt-3 text-lg leading-7 text-gray-500">
              With all your data in one place, Odoo ERP monitors your business
              in real time, watching for opportunities to do useful work{' '}
              <strong>automatically</strong>.
            </p>
            <ul className="mt-10">
              <li>
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-12 h-12 text-white bg-teal-500 rounded-md">
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <h5 className="text-lg font-medium leading-6 text-gray-900">
                      The boring stuff happens automatically
                    </h5>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Odoo ERP can automatically create purchase orders to
                      replenish low inventory, send email reminders to customers
                      with overdue accounts, enforce quality standards, and much
                      more.
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-10">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-12 h-12 text-white bg-teal-500 rounded-md">
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <h5 className="text-lg font-medium leading-6 text-gray-900">
                      Customized reports show up when you need them
                    </h5>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      It is easy to create reports showing just the data
                      you&apos;ll find helpful, in easy to understand formats,
                      delivered on a regular schedule or only when specific
                      conditions arise.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="relative mt-10 -mx-4 lg:mt-0 lg:col-start-1">
            <svg
              className="absolute transform -translate-x-1/2 translate-y-16 left-1/2 lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
            >
              <defs>
                <pattern
                  id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={784}
                height={404}
                fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
              />
            </svg>
            <Robust101 className="relative mx-auto" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

WhatIsERP.propTypes = {
  bgcolor: PropTypes.string,
};

WhatIsERP.defaultProps = {
  bgcolor: 'bg-white',
};

export default WhatIsERP;
